import {Util} from "./util.js";

let util = new Util();

export class Article {

    constructor(dom, comboInfoForArticle = {comboType: "UNKNOWN"}, isArticleInTopicPage = false) {
        this.dom = dom;
        this.id = util.readFromDataAttribute(dom, "article_id");
        this.variationId = util.readFromDataAttribute(dom, "variation_id");
        this._variationsForArticle = JSON.parse(util.readFromDataAttribute(dom, "variation_ids"));
        this._onWishlist = dom.querySelector(".p_selectBtn50--selected") !== null;
        this._wishlistHeartDom = dom.querySelector(".combo_article--wishlistheart");
        this.position = parseInt(util.readFromDataAttribute(dom, "article_position"))
        this.comboType = comboInfoForArticle.comboType.charAt(0).toUpperCase() + comboInfoForArticle.comboType.slice(1).toLowerCase()
        this.isArticleInTopicPage = isArticleInTopicPage;
    }

    addClickHandlerForWishlistBtn() {
        this.wishlistHeartDom.addEventListener("click", this._toggleHeartWishlistHeartOnClick())
    }

    _toggleHeartEnabled(heartBtn, enabled, initialSetup) {
        const defaultClasses = 'combo_article--wishlistheart p_selectBtn50';
        if (!initialSetup) {
            if (enabled) {
                heartBtn.className = defaultClasses + ' p_selectBtn50--animated p_selectBtn50--selected';
            } else {
                heartBtn.className = defaultClasses + ' p_selectBtn50--animated-reverse';
            }
        } else {
            if (enabled) {
                heartBtn.className = defaultClasses + ' p_selectBtn50--selected';
            }
        }
    }

    _toggleHeartWishlistHeartOnClick() {
        const self = this;
        return function (event) {
            event.stopPropagation(); /*                                                       */
            self.onWishlist = !self.onWishlist;
            self._toggleHeartEnabled(self.wishlistHeartDom, self.onWishlist, false);
            if (self.onWishlist) {
                window.o_global.eventQBus.emit('ft1.wishlist.add',
                    {
                        variationId: self.variationId,
                        addedFrom: self._getWishListAddEventAddedFrom(self),
                        qualified: false,
                        name: self.variationId /*                                                */
                    });
            } else {
                window.o_global.eventQBus.emit('ft1.wishlist.remove', {
                    variationIds: self.variationsForArticle,
                    deletedFrom: self._getWishListAddEventAddedFrom(self)
                });
            }
        }
    }

    _getWishListAddEventAddedFrom(self) {
        return (self.isArticleInTopicPage ? `SingleComboTopic` : `SingleCombo${self.comboType}`);
    }

    getDom() {
        return this.dom;
    }

    getId() {
        return this.id;
    }

    getVariationId() {
        return this.variationId;
    }

    getPosition() {
        return this.position;
    }

    get variationsForArticle() {
        return this._variationsForArticle;
    }

    get onWishlist() {
        return this._onWishlist;
    }

    set onWishlist(value) {
        this._onWishlist = value;
    }

    get wishlistHeartDom() {
        return this._wishlistHeartDom;
    }

}
