import {Article} from "./article.js";
import {Util} from "./util.js";

let util = new Util();

export class Combo {
    constructor(dom) {
        this.dom = dom;
        this.articleDoms = Array.prototype.slice.call(dom.getElementsByClassName("combo_article"), 0);
        this.articles = [];
        this.id = util.readFromDataAttribute(dom, "combo_id");
        this.source = util.readFromDataAttribute(dom, "combo_src");
        this.presentation = util.readFromDataAttribute(dom, "combo_pres");
        this.articleCount = util.readFromDataAttribute(dom, "article_count");
        this.topicId = util.readFromDataAttribute(dom, "combo_topic_id");
        this._articleNumberFromADS = util.readFromDataAttribute(dom, "article_number_from_ads");
        this.comboType = util.readFromDataAttribute(dom, "combo_type");
        this._pageType = util.readFromDataAttribute(dom, "page_type");
        const isArticleInTopicPage = this._pageType === "TOPIC";

        for (let i = 0; i < this.articleDoms.length; i++) {
            const comboInfoForArticle = {comboType: this.comboType}
            this.articles.push(new Article(this.articleDoms[i], comboInfoForArticle, isArticleInTopicPage));
        }
    }

    isComingFromArticleDetailPage() {
        return (this._articleNumberFromADS || "").length > 0;
    }

    addClickHandlers() {
        const links = this.dom.querySelectorAll("a");
        for (let i = 0; i < links.length; i++) {
            links[i].addEventListener("click", window.o_combo.trackHelper.clickHandlerForArticleClickTracking(i, this));
        }
        this.addClickHandlerForEek();
    }

    addWishlistButtonClickHandler() {
        this.articles.forEach(article => article.addClickHandlerForWishlistBtn());
    }

    addClickHandlerForEek() {
        const energyEfficiencyLabels = this.dom.querySelectorAll(".combo_hasEnergyEfficiencyLabel");
        for (let index = 0; index < energyEfficiencyLabels.length; index++) {
            let energyEfficiencyLabel = energyEfficiencyLabels[index];
            energyEfficiencyLabel.addEventListener("click", () => {
                this._openEnergyEfficiencyLayer(
                    energyEfficiencyLabel.getAttribute("data-image-url"),
                    energyEfficiencyLabel.getAttribute("data-category"),
                    energyEfficiencyLabel.getAttribute("data-title"),
                    o_global.pali)
            });
        }
    }

    sendViewTracking() {
        window.o_combo.trackHelper.trackViewForSingleCombo(this);
    }

    _findParentArticle(elem) {
        while (!elem.classList.contains("combo-article") && (elem = elem.parentElement)) ;
        return elem;
    }

    _openEnergyEfficiencyLayer(dataImageUrls, category, component, pali) {
        const dataImageUrlArray = dataImageUrls.split(",");

        const title = `<h2 class="p_layer__headline">${component}</h2>`
        const energyEfficiencyLayer =
            `<div class="combo_energyEfficiencyImageLayer">` +

            dataImageUrlArray.map(dataImageUrl =>
                `<picture>` +
                `<source srcset="${dataImageUrl}&fmt=webp" type="image/webp">` +
                `<img src="${dataImageUrl}" class="combo_energyEfficiencyImageLayer__image" alt="Energy efficiency class: ${category}"/>` +
                `</picture>`
            ) + `</div>`;

        let modalContentHTML;
        if (component !== null) {
            if (component.length > 0) modalContentHTML = title + energyEfficiencyLayer
        } else {
            modalContentHTML = energyEfficiencyLayer
        }

        const options = {
            modal: true,
            width: "550px",
            height: "585px",
            content: modalContentHTML,
            createTrackingContext: false
        };

        const layer = new pali.layerBuilder(options);
        layer.open();
    }

    getDom() {
        return this.dom;
    }

    getId() {
        return this.id;
    }

    getArticles() {
        return this.articles;
    }

    getArticleCount() {
        return this.articleCount;
    }

    getSource() {
        return this.source;
    }

    getPresentation() {
        return this.presentation;
    }

    getTopicId() {
        return this.topicId;
    }

    getComboType() {
        return this.comboType;
    }

    getArticleNumberFromADS() {
        return this._articleNumberFromADS;
    }

    get pageType() {
        return this._pageType;
    }
}
