import {Combo} from "./combo.js";

export class Initialisation {
    initialize(comboContents) {
        let combo;
        [].forEach.call(comboContents, comboElement => {
            if (!this._isInitialized(comboElement)) {
                combo = new Combo(comboElement)

                this._setIsInitialized(comboElement);
                combo.addClickHandlers();
                combo.addWishlistButtonClickHandler();
                combo.sendViewTracking();
            }
        });
    }

    _isInitialized(comboElement) {
        return comboElement.getAttribute("data-tracked") === "true"
    }

    _setIsInitialized(comboElement) {
        comboElement.setAttribute("data-tracked", "true");
    }

}
