export default function initTopicPromoTracking(_document) {
    const topicPromotionContainer = _document.getElementById("topicPromotionContainer")

    const tilesSections = Array.from(_document.getElementsByTagName('section')).filter(section =>
        section.className.includes("combo_presentation-additional_combos") || section.className.includes("combo_presentation-similar_combos")
    );
    const thereAreAnyTypeOfTiles = tilesSections && tilesSections.length > 0;
    const thereAreSimilarCombosInTheSecondPosition = tilesSections.length > 1 && tilesSections[1].className.includes("combo_presentation-similar_combos");
    if (thereAreAnyTypeOfTiles) {
        thereAreSimilarCombosInTheSecondPosition ?
            topicPromotionContainer.setAttribute("data-feature-order", 4) : topicPromotionContainer.setAttribute("data-feature-order", 3)
    } else {
        topicPromotionContainer.setAttribute("data-feature-order", 2)
    }
}
