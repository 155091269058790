import {TrackHelper} from "./track_helper";

export default function initMoreCombosTracking(_document) {
    const tilesSections = Array.from(_document.getElementsByTagName('section')).filter(section =>
        section.className.includes("combo_presentation-additional_combos") || section.className.includes("combo_presentation-similar_combos")
    );

    const thereAreAnyTypeOfTiles = tilesSections && tilesSections.length > 0;
    const thereAreAdditionalCombosInTheFirstPosition = tilesSections[0].className.includes("combo_presentation-additional_combos");
    const thereAreSimilarCombosInTheFirstPosition = tilesSections[0].className.includes("combo_presentation-similar_combos");
    const thereAreSimilarCombosInTheSecondPosition = tilesSections.length > 1 && tilesSections[1].className.includes("combo_presentation-similar_combos");

    if (thereAreAnyTypeOfTiles) {
        if (thereAreAdditionalCombosInTheFirstPosition) {
            new MoreCombosTracking(tilesSections[0], 2)
            if (thereAreSimilarCombosInTheSecondPosition) {
                new MoreCombosTracking(tilesSections[1], 3)
            }
        } else if (thereAreSimilarCombosInTheFirstPosition) {
            new MoreCombosTracking(tilesSections[0], 2)
        }
    }
}

class MoreCombosTracking {

    constructor(section, position) {
        const tilesContainers = section.getElementsByClassName('combo_presentation-tiles_tracking');
        if (tilesContainers && tilesContainers.length > 0) {
            this.tiles = Array.from(tilesContainers[0].querySelectorAll('a'));
            this.trackHelper = new TrackHelper();
            this.promoType = tilesContainers[0].getAttribute('data-promo-type')
            this.isRoom = tilesContainers[0].getAttribute('data-is-room')
            this.attachClickEventListenersToTiles(position);
            this.trackViewEvent(position);
        }
    }

    trackViewEvent(position) {
        const viewTrackingInfo = {
            "tiles": this.tiles.map(item => {
                    return {
                        "id": item.getAttribute('data-combo-id'),
                        "similarityScore": item.getAttribute('data-similarity-score'),
                        "comboSource": item.getAttribute('data-combo-source')
                    }
                }
            )
        };
        const isACFA = this.promoType === "Wozda"
        const comboType = this.isRoom === "true" ? "ROOM" : "OUTFIT"
        this.trackHelper.sendTileViewTrackingEvent(viewTrackingInfo, position, isACFA, comboType);
    }

    attachClickEventListenersToTiles(position) {
        const isACFA = this.promoType === "Wozda"
        const comboType = this.isRoom === "true" ? "ROOM" : "OUTFIT"
        this.tiles.forEach((item, index) => item.addEventListener("click", () =>
            this.trackHelper.sendTileClickTrackingEvent(this.getSimilarityTrackingInfo(item, index), position, isACFA, comboType)));
    }

    getSimilarityTrackingInfo(item, index) {
        return {
            clickedCombo: {
                id: item.getAttribute('data-combo-id'),
                similarityScore: item.getAttribute('data-similarity-score'),
                position: index + 1,
                comboSource: item.getAttribute('data-combo-source')
            },
            numberOfCombos: this.tiles.length
        };
    }
}
