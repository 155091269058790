export class TopicPromotionLoader {
    loadTopicPromotion(id) {
        let elementToBeReplaced = document.getElementById(id),
            xhr = new XMLHttpRequest();

        function ready() {
            let HTTP_OK = 200,
                READY_STATE_DONE = 4;

            if (xhr.readyState === READY_STATE_DONE) {
                if (xhr.status === HTTP_OK) {
                    elementToBeReplaced.outerHTML = xhr.responseText;
                    const topicPromotionContainer = document.getElementById("topicPromotionContainer")
                    window.o_util.hardcore.executeInlineScripts(topicPromotionContainer);
                    window.invokePreload.processPreloadsOnElement(topicPromotionContainer);
                } else {
                    removeElement(elementToBeReplaced);
                }
            }
        }

        function removeElement(elementToBeReplaced) {
            if (elementToBeReplaced && elementToBeReplaced.parentNode) {
                elementToBeReplaced.parentNode.removeChild(elementToBeReplaced);
            }
        }

        function timeout() {
            elementToBeReplaced.remove();
        }

        if (elementToBeReplaced) {
            let url = elementToBeReplaced.getAttribute("data");
            if (url != null && url != "") {
                xhr.open("get", url, true);
                xhr.timeout = 5000;
                xhr.ontimeout = timeout;
                xhr.onreadystatechange = ready;
                xhr.send(null);
            } else {
                removeElement(elementToBeReplaced)
            }
        }
    }
}
